<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Halter
      </h4>
    </template>
    <template #body>
      <form
        novalidate
        @submit.prevent
      >
        <div class="form-row my-3">
          <div class="col-12 col-md-6">
            <basic-select
              id="VNA"
              v-model="order.HALTER.VNA"
              vias="VNA"
              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
              placeholder="Anrede"
              :horizontal="true"
              :margin="false"
              label="Anrede"
              :options="[
                {value: null},
                {value: 'Frau', value: 'w'},
                {label: 'Herr', value: 'm'},
                {label: 'Firma', value: 'f'},
              ]"
            />
          </div>
          <div class="col-12 col-md-3">
            <basic-select
              id="title-tit"
              v-model="order.HALTER.TI1"
              data-test="owner-form-title-tit"
              :margin="false"
              vias="TI1"
              placeholder="Titel"
              :disabled="extend"
              :options="[
                {value: null},
                {label: 'Dr.', value: 'DR'},
                {label: 'Prof.', value: 'PROF'},
                {label: 'Prof. Dr.', value: 'PROF_DR'}
              ]"
            />
          </div>
          <div class="col-12 col-md-3">
            <basic-select
              id="title-pre"
              v-model="order.HALTER.TI2"
              data-test="owner-form-title-pre"
              :margin="false"
              placeholder="Titel"
              vias="TI2"
              :disabled="extend"
              :options="[
                {value: null},
                {label: 'Baron', value: 'Baron'},
                {label: 'Baronin', value: 'Baronin'},
                {label: 'Freifrau', value: 'Freifrau'},
                {label: 'Freiherr', value: 'Freiherr'},
                {label: 'Fürst', value: 'Fürst'},
                {label: 'Fürstin', value: 'Fürstin'},
                {label: 'Graf', value: 'Graf'},
              ]"
            />
          </div>
        </div>
        <basic-input
          id="firstname"
          v-model="order.HALTER.VNV"
          vias="VNV"
          data-test="owner-form-firstname"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Vorname"
          :horizontal="true"
          :disabled="extend"
          label="Vorname"
          required
        />

        <basic-input
          id="lastname"
          v-model="order.HALTER.VNN"
          vuas="VNN"
          data-test="owner-form-lastname"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Nachname"
          :horizontal="true"
          :disabled="extend"
          label="Nachname"
          required
        />

        <basic-input
          id="additional"
          v-model="order.HALTER.NA2"
          data-test="owner-form-additional"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Zusatz"
          vias="NA2"
          :disabled="extend"
          :horizontal="true"
          label="Zusatz"
        />

        <basic-input
          id="street"
          v-model="order.HALTER.STR"
          visa="STR"
          data-test="owner-form-street"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Straße, Nr."
          :horizontal="true"
          label="Straße, Nr."
          required
        />

        <div class="form-row my-3">
          <div class="col-12 col-md-6">
            <basic-input
              v-if="$system === 'at'"
              id="city-zip"
              v-model="order.HALTER.PLZ"
              vias="PLZ"
              data-test="owner-form-city-zip"
              name="PLZ"
              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
              placeholder="PLZ"
              :horizontal="true"
              label="PLZ / Ort"
              :margin="false"
              required
            />
            <basic-typeahead
              v-else
              id="city-zip"
              v-model="order.HALTER.PLZ"
              vias="PLZ"
              data-test="owner-form-city-zip"
              name="PLZ"
              auto-select-first
              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
              placeholder="PLZ"
              :query="searchCityByZip"
              :input-formatter="city => city.zip.toString()"
              :result-formatter="cityResultFormatter"
              :horizontal="true"
              label="PLZ / Ort"
              :margin="false"
              required
              @hit="setCity"
            />
          </div>
          <div class="col-12 col-md-6">
            <basic-input
              v-if="$system === 'at'"
              id="city-name"
              v-model="order.HALTER.ORT"
              vias="ORT"
              data-test="owner-form-city-name"
              name="Stadt"
              autocomplete="off"
              :margin="false"
              required
              placeholder="Ort"
            />
            <basic-typeahead
              v-else
              id="city-name"
              v-model="order.HALTER.ORT"
              vias="ORT"
              data-test="owner-form-city-name"
              name="Stadt"
              auto-select-first
              :query="searchCityByName"
              :input-formatter="city => city.name.toString()"
              :result-formatter="cityResultFormatter"
              autocomplete="off"
              :margin="false"
              required
              placeholder="Ort"
              @hit="setCity"
            />
          </div>
        </div>

        <div class="form-row my-3">
          <div class="col-12 col-md-6">
            <basic-typeahead
              id="country-char"
              v-model="order.HALTER.NAT"
              data-test="owner-form-country-char"
              name="Land"
              vias="NAT"
              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
              placeholder="Land"
              :horizontal="true"
              label="Land"
              :margin="false"
              :query="searchCountry"
              :input-formatter="country => country.name.toString()"
              :result-formatter="countryResultFormatter"
              @input="$delete(order.HALTER.NAT_Text)"
              @hit="setCountry"
            />
          </div>
        </div>

        <basic-tel-input
          id="mobile"
          v-model="order.HALTER.MOBIL_PRIVAT"
          vias="MOBIL_PRIVAT"
          data-test="owner-form-mobile"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Mobiltelefonnummer"
        />

        <basic-input
          id="phone"
          v-model="order.HALTER.TEL"
          vias="TEL"
          data-test="owner-form-phone"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Festnetznummer"
          :horizontal="true"
          label="Festnetznummer"
        />

        <basic-input
          id="email"
          v-model="order.HALTER.MAIL_PRIVAT"
          vias="MAIL_PRIVAT"
          data-test="owner-form-email"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="E-Mail"
          :horizontal="true"
          label="E-Mail"
        />

        <basic-datepicker
          v-model="order.HALTER.GEBDAT"
          vias="GEBDAT"
          data-test="owner-form-birthday"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="TT.MM.JJJJ"
          :horizontal="true"
          label="Geburtstag"
        />

        <basic-datepicker
          v-model="order.HALTER.ADA"
          vias="ADA"
          data-test="owner-form-date-of-application"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="TT.MM.JJJJ"
          :horizontal="true"
          label="Antrags-Datum"
        />
        <basic-input
          v-model="order.HALTER.ADABEM"
          vias="ADABEM"
          data-test="owner-application-notice"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Antragshinweis"
          :horizontal="true"
          label="Antragshinweis"
        />
        <basic-datepicker
          v-model="order.HALTER.POLICENVERSAND"
          vias="POLICENVERSAND"
          data-test="owner-form-"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="TT.MM.JJJJ"
          :horizontal="true"
          label="Antrags-Datum"
        />
        <basic-input
          v-model="order.HALTER.DOK_SPRACHE"
          vias="DOK_SPRACHE"
          data-test="owner-application-notice"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Antragshinweis"
          :horizontal="true"
          label="Antragshinweis"
        />
        <basic-input
          v-model="order.HALTER.PARTNERNR"
          vias="PARTNERNR"
          data-test="owner-application-notice"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Partner Nr,"
          :horizontal="true"
          label="Partner Nr,"
        />
        <basic-checkbox
          v-model="order.HALTER.AGB_DRUCK"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="AGB_DRUCK"
          label="AGB ausgedruckt"
        />
        <basic-checkbox
          v-model="order.HALTER.POLICE_DRUCK"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="POLICE_DRUCK"
          label="Police Ausgedruckt"
        />
        <basic-textarea
          v-model="order.HALTER.HINWEIS_VTG"
          vias="HINWEIS_VTG"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Hinweis zum Vertrag"
          placeholder="Hinweis zum Vertrag"
          required
        />
        <basic-textarea
          v-model="order.HALTER.HINWEIS_INTERN"
          vias="HINWEIS_INTERN"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Hinweis zum Intern"
          placeholder="Hinweis zum Intern"
          required
        />
        <basic-textarea
          v-model="order.HALTER.HINWEIS_RG"
          vias="HINWEIS_RG"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Hinweis zum Rechnung"
          placeholder="Hinweis zum Rechnung"
          required
        />
      </form>
    </template>
  </ca-card>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox'
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead'
import BasicTelInput from '@/components/BaseComponents/BasicTelInput'
import BasicTextarea from '@/components/BaseComponents/BasicTextarea'
import CaCard from '@/components/Card'

export default {
  inject: ['$validator'],
  name: 'HalterForm',
  components: {
    BasicInput,
    BasicSelect,
    CaCard,
    BasicDatepicker,
    BasicCheckbox,
    BasicTypeahead,
    BasicTelInput,
    BasicTextarea
  },
  props: {
    // order: {
    //   type: Object,
    //   required: true
    // },
    extend: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      order: {
        HALTER: {}
      }
    }
  },
  methods: {
    searchCityByZip (zip) {
      return this.$store.dispatch('zipcodes/find', {
        query: {
          $limit: 50,
          zip: { $regex: '^' + zip, $options: 'i' }
        }
      })
    },
    searchCityByName (name) {
      return this.$store.dispatch('zipcodes/find', {
        query: {
          $limit: 50,
          name: { $regex: '^' + name, $options: 'i' }
        }
      })
    },
    cityResultFormatter (city) {
      return `${city.zip} ${city.name}`
    },
    setCity (city) {
      this.order.owner.city = {
        ...city
      }
    },
    searchCountry (char) {
      return this.$store.dispatch('countries/find', {
        query: {
          $limit: 10, char: char.toUpperCase()
        }
      })
    },
    countryResultFormatter (country) {
      return `${country.char} ${country.name}`
    },
    setCountry (country) {
      this.order.owner.country = {
        ...country
      }
    }
  }
}
</script>
