<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Vertrag
      </h4>
    </template>
    <template #body>
      <form
        novalidate
        @submit.prevent
      >
        <basic-input
          v-model="order.VERTRAG.VSN"
          vias="VSN"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Vertragsnummer"
          :horizontal="true"
          :disabled="extend"
          label="Vertragsnummer"
        />
        <basic-input
          v-model="order.VERTRAG.GES"
          vias="GES"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Versicherungsgesellschaft gem. Gesellschaftendatei"
          :horizontal="true"
          :disabled="extend"
          label="Versicherungsgesellschaft gem. Gesellschaftendatei"
        />
        <basic-input
          v-model="order.VERTRAG.VMT"
          vias="VMT"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="1. Vermittlernummer"
          :horizontal="true"
          :disabled="extend"
          label="1. Vermittlernummer"
        />
        <basic-input
          v-model="order.VERTRAG.VMT2"
          vias="VMT2"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="2. Vermittlernummer"
          :horizontal="true"
          :disabled="extend"
          label="2. Vermittlernummer"
        />
        <basic-datepicker
          v-model="order.VERTRAG.VGES"
          vias="VGES"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="TT.MM.JJJJ"
          :horizontal="true"
          label="Risiko gemeldet am"
        />
        <basic-select
          id="OPZ"
          v-model="order.VERTRAG.OPZ"
          vias="OPZ"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Vertragsart"
          :horizontal="true"
          label="Vertragsart"
          :options="OPZOptions"
        />
        <basic-select
          id="STA"
          v-model="order.VERTRAG.STA"
          vias="STA"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Abrechungsstatus"
          :horizontal="true"
          label="Abrechungsstatus"
          :options="STAOptions"
        />
        <basic-input
          v-model="order.VERTRAG.BEIN"
          vias="BEIN"
          type="number"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Beitrag"
          :horizontal="true"
          :disabled="extend"
          label="Beitrag"
        />
        <basic-select
          id="BRUTTO"
          v-model="order.VERTRAG.STA"
          vias="BRUTTO"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Art des eingegebenen Beitrags"
          :horizontal="true"
          label="Art des eingegebenen Beitrags"
          :options="BRUTTOOptions"
        />
        <basic-input
          v-model="order.VERTRAG.NET"
          vias="NET"
          type="number"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Netto Beitrag gemäß Zahlungsweise"
          :horizontal="true"
          :disabled="extend"
          label="Netto Beitrag gemäß Zahlungsweise"
        />
        <basic-input
          v-model="order.VERTRAG.BRT"
          vias="BRT"
          type="number"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Beitrag gemäß Zahlungsweise incl. VersSt. und Gebühr"
          :horizontal="true"
          :disabled="extend"
          label="Beitrag gemäß Zahlungsweise inkl. VersSt. und Gebühr"
        />
        <basic-input
          v-model="order.VERTRAG.BGR"
          vias="BGR"
          type="number"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Provisions-Bemessungsgrundlage"
          :horizontal="true"
          :disabled="extend"
          label="Provisions-Bemessungsgrundlage"
        />
        <basic-input
          v-model="order.VERTRAG.VST"
          vias="VST"
          type="number"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Vers.-Steuersatz"
          :horizontal="true"
          :disabled="extend"
          label="Vers.-Steuersatz"
        />
        <basic-input
          v-model="order.VERTRAG.VSTBetrag"
          vias="VSTBetrag"
          type="number"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="VST Betrag"
          :horizontal="true"
          :disabled="extend"
          label="VST Betrag"
        />
        <basic-input
          v-model="order.VERTRAG.NJB"
          vias="NJB"
          type="number"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Netto Jahresbeitrag"
          :horizontal="true"
          :disabled="extend"
          label="Netto Jahresbeitrag"
        />
        <basic-input
          v-model="order.VERTRAG.BJB"
          vias="BJB"
          type="number"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Brutto-Jahresbeitrag"
          :horizontal="true"
          :disabled="extend"
          label="Brutto-Jahresbeitrag"
        />
        <basic-input
          v-model="order.VERTRAG.ZFZ"
          vias="ZFZ"
          type="number"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Zuschlag für unterjährige Zahlweise in % (in Folgebeitrag enthalten)"
          :horizontal="true"
          :disabled="extend"
          label="Zuschlag für unterjährige Zahlweise in % (in Folgebeitrag enthalten)"
        />
        <basic-select
          id="INK"
          v-model="order.VERTRAG.INK"
          vias="INK"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Inkasso-Art"
          :horizontal="true"
          label="Inkasso-Art"
          :options="INKOptions"
        />
        <basic-select
          id="RKZ"
          v-model="order.VERTRAG.RKZ"
          vias="RKZ"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Rechnungsdruck"
          :horizontal="true"
          label="Rechnungsdruck"
          :options="RKZOptions"
        />
        <basic-select
          id="MKZ"
          v-model="order.VERTRAG.MKZ"
          vias="MKZ"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Mahnkennzeichen"
          :horizontal="true"
          label="Mahnkennzeichen"
          :options="MKZOptions"
        />
        <basic-input
          v-model="order.VERTRAG.ABW"
          vias="ABW"
          type="number"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="abw. Rechnungsanschrift (Kundennummer des Empfängers)"
          :horizontal="true"
          :disabled="extend"
          label="abw. Rechnungsanschrift (Kundennummer des Empfängers)"
        />
        <basic-input
          v-model="order.VERTRAG.DNP"
          vias="DNP"
          type="number"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Dauernachlaß (für Laufzeit) in P"
          :horizontal="true"
          :disabled="extend"
          label="Dauernachlaß (für Laufzeit) in P"
        />
        <basic-select
          id="ZAA"
          v-model="order.VERTRAG.ZAA"
          vias="ZAA"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Zahlungsart"
          :horizontal="true"
          label="Zahlungsart"
          :options="ZAAOptions"
        />
        <basic-input
          v-model="order.VERTRAG.HFL"
          vias="HFL"
          type="number"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="TTMM"
          :horizontal="true"
          :disabled="extend"
          label="Hauptfälligkeit"
        />
        <basic-datepicker
          v-model="order.VERTRAG.BEG"
          vias="BEG"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="TT.MM.JJJJ"
          :horizontal="true"
          label="Beginn der Zahlung"
        />
        <basic-datepicker
          v-model="order.VERTRAG.ABL"
          vias="ABL"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="TT.MM.JJJJ"
          :horizontal="true"
          label="Ende der Zahlung"
        />
        <basic-input
          v-model="order.VERTRAG.BANKNR"
          vias="BANKNR"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Kontonummer  Abweichender Kontoinhaber = Axxx   xxx=lfd. Nummer"
          :horizontal="true"
          :disabled="extend"
          label="Kontonummer  Abweichender Kontoinhaber = Axxx   xxx=lfd. Nummer"
        />
        <!-- <basic-select
          id="WAE"
          v-model="order.VERTRAG.WAE"
          vias="WAE"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Währung"
          :horizontal="true"
          label="Währung"
          :options="WAEOptions"
        /> -->
        <basic-datepicker
          v-model="order.VERTRAG.EDA"
          vias="EDA"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="TT.MM.JJJJ"
          :horizontal="true"
          label="Datum der letzten Änderung"
        />
        <basic-datepicker
          v-model="order.VERTRAG.DST"
          vias="DST"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="TT.MM.JJJJ"
          :horizontal="true"
          label="Storno am"
        />
        <basic-datepicker
          v-model="order.VERTRAG.BEMDAT"
          vias="BEMDAT"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="TT.MM.JJJJ"
          :horizontal="true"
          label="Aantrags-Datum"
        />
        <basic-input
          v-model="order.VERTRAG.EGR"
          vias="EGR"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Änderungs-Grund"
          :horizontal="true"
          :disabled="extend"
          label="Änderungs-Grund"
        />
        <basic-input
          v-model="order.VERTRAG.AGR"
          vias="AGR"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Storno-Grund"
          :horizontal="true"
          :disabled="extend"
          label="Storno-Grund"
        />
        <basic-input
          v-model="order.VERTRAG.AGR"
          vias="AGR"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Storno-Grund"
          :horizontal="true"
          :disabled="extend"
          label="Storno-Grund"
        />
        <basic-textarea
          v-model="order.VERTRAG.Indi9"
          vias="Indi9"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Hinweis zum Vertrag"
          placeholder="Hinweis zum Vertrag"
          required
        />
      </form>
    </template>
  </ca-card>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicTextarea from '@/components/BaseComponents/BasicTextarea'
import CaCard from '@/components/Card'

export default {
  inject: ['$validator'],
  name: 'VertragForm',
  components: {
    BasicInput,
    CaCard,
    BasicDatepicker,
    BasicSelect,
    BasicTextarea
  },
  props: {
    // order: {
    //   type: Object,
    //   required: true
    // },
    extend: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    order: {
      VERTRAG: {}
    },
    OPZOptions: [
      { label: 'Fremdvertrag', value: 'F' },
      { label: 'Eigenvertrag', value: 'E' },
      { label: 'Angebot', value: '?' },
      { label: 'Antrag', value: '$' },
      { label: 'Online', value: 'O' }
    ],
    STAOptions: [
      { label: 'Aktiv für Abrechnung', value: 'A' },
      { label: 'Storno für Abrechnung', value: 'S' }
    ],
    BRUTTOOptions: [
      { label: 'Brutto', value: 'BRUTTO' },
      { label: 'Netto', value: 'NETTO' }
    ],
    INKOptions: [
      { label: 'Direktinkasso', value: '0' },
      { label: 'Agenturinkasso', value: '1' },
      { label: 'Sonderinkasso', value: '2' }
    ],
    RKZOptions: [
      { label: 'Keine Rechnung', value: '0' },
      { label: 'Erstrechnung', value: '1' },
      { label: 'Folgerechnung', value: '2' }
    ],
    MKZOptions: [
      { label: 'Mahnung', value: '0' },
      { label: 'Mahnausschluß', value: '1' }
    ],
    ZAAOptions: [
      { label: 'keine Lastschrift', value: '0' },
      { label: 'Lastschrift', value: '1' }
    ],
    WAEOptions: [
      { label: 'Euro', value: 'EUR' },
      { label: 'Schweizer Franken', value: 'CHF' }
    ]
  }),
  methods: {
  }
}
</script>
