<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Fahrzeug
      </h4>
    </template>
    <template #body>
      <form
        novalidate
        @submit.prevent="save"
      >
        <basic-input
          v-model="FAHRZEUG.HSN"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Hersteller-Schlüssel-Nummer"
          :horizontal="true"
          label="Hersteller-Schlüssel-Nummer"
          vias="HSN"
        />
        <basic-input
          v-model="FAHRZEUG.TSN"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Typ-Schlüsselnummer"
          :horizontal="true"
          label="Typ-Schlüsselnummer"
          vias="TSN"
        />
        <basic-input
          v-model="FAHRZEUG.KZNAT"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Land Kennzeichen"
          :horizontal="true"
          label="Land Kennzeichen"
          vias="KZNAT"
        />
        <basic-input
          v-model="FAHRZEUG.Kennzeichen_Kfz"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Kennzeichen"
          :horizontal="true"
          label="Kennzeichen"
          vias="Kennzeichen_Kfz"
        />
        <basic-input
          v-model="FAHRZEUG.Kennz_Art"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Kennzeichen Art"
          :horizontal="true"
          label="Kennzeichen Art"
          vias="Kennz_Art"
        />
        <basic-input
          v-model="FAHRZEUG.HST"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Hersteller"
          :horizontal="true"
          label="Hersteller"
          vias="HST"
        />
        <basic-input
          v-model="FAHRZEUG.TYP"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Modellname"
          :horizontal="true"
          label="Modellname"
          vias="TYP"
        />
        <basic-input
          v-model="FAHRZEUG.BAUJAHR"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Baujahr"
          :horizontal="true"
          label="Baujahr"
          vias="BAUJAHR"
        />
        <basic-datepicker
          v-model="FAHRZEUG.ZUL"
          data-test="car-form-registration-date"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          required
          label="Erstzulassung"
          placeholder="TT.MM.JJJJ"
          vias="ZUL"
        />

        <basic-input
          v-model="FAHRZEUG.KFZALTER"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Alter in Monaten"
          :horizontal="true"
          type="number"
          label="Alter in Monaten"
          vias="KFZALTER"
        />
        <basic-input
          v-model="FAHRZEUG.Lccm"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Leistung in ccm"
          type="number"
          :horizontal="true"
          label="Leistung in ccm"
          vias="Lccm"
        />
        <basic-input
          v-model="FAHRZEUG.LKW"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Leistung in Kilowatt "
          :horizontal="true"
          type="number"
          label="Leistung in Kilowatt "
          vias="LKW"
        />
        <basic-datepicker
          v-model="FAHRZEUG.KMSTANDDATUM"
          data-test="car-form-registration-date"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          required
          label="Kilometerstand abgelesen am"
          placeholder="TT.MM.JJJJ"
          vias="KMSTANDDATUM"
        />
        <basic-input
          v-model="FAHRZEUG.KMS"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Kilometerstand"
          :horizontal="true"
          label="Kilometerstand"
          type="number"
          vias="KMS"
        />

        <BasicCurrencyInput
          v-if="!isKaufschutz"
          v-model="FAHRZEUG.KPVN"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          placeholder="Kaufpreis VN"
          label="Kaufpreis VN"
          vias="KPVN"
        >
          <template #append>
            <span class="input-group-text">€</span>
          </template>
        </BasicCurrencyInput>
        <BasicCurrencyInput
          v-if="!isKaufschutz"
          v-model="FAHRZEUG.P01"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          placeholder="Fahrzeugneuwert"
          label="Fahrzeugneuwert"
          vias="P01"
        >
          <template #append>
            <span class="input-group-text">€</span>
          </template>
        </BasicCurrencyInput>
        <basic-datepicker
          v-model="FAHRZEUG.ERW"
          data-test="car-form-registration-date"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          required
          label="Erwerbsdatum"
          placeholder="TT.MM.JJJJ"
          vias="ERW"
        />
        <basic-input
          v-model="FAHRZEUG.KRFANT"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Kraftstoff oder Antriebsart"
          :horizontal="true"
          label="Kraftstoff oder Antriebsart"
          vias="KRFANT"
        />
        <basic-datepicker
          v-model="FAHRZEUG.LANMVN"
          data-test="car-form-registration-date"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          required
          label="letzte Abmeldungss"
          placeholder="TT.MM.JJJJ"
          vias="LANMVN"
        />
        <basic-datepicker
          v-model="FAHRZEUG.LABMEL"
          data-test="car-form-registration-date"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          required
          label="letzte Abmeldung"
          placeholder="TT.MM.JJJJ"
          vias="LABMEL"
        />
        <basic-input
          v-model="FAHRZEUG.SKennBis"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="MM"
          :horizontal="true"
          label="Saisondauer bis"
          vias="SKennBis"
        />
        <basic-input
          v-model="FAHRZEUG.SKennVon"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="MM"
          :horizontal="true"
          label="Saisondauer von"
          vias="SKennVon"
        />
        <basic-input
          v-model="FAHRZEUG.NUA"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Nutzungsart"
          :horizontal="true"
          label="Nutzungsart"
          vias="NUA"
        />
        <basic-select
          id="KFZGUT"
          v-model="FAHRZEUG.KFZGUT"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Ja/Nein"
          :horizontal="true"
          :margin="false"
          label="Kfz Gutachten"
          vias="KFZGUT"
          :options="JNOptions"
        />
        <basic-input
          v-model="FAHRZEUG.BVE"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Besondere Vereinbarung"
          :horizontal="true"
          label="Besondere Vereinbarung"
          vias="BVE"
        />
        <basic-input
          v-model="FAHRZEUG.BVE2"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder=""
          :horizontal="true"
          label=""
          vias="BVE2"
        />
        <basic-input
          v-model="FAHRZEUG.BVE3"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder=""
          :horizontal="true"
          label=""
          vias="BVE3"
        />
        <basic-input
          v-model="FAHRZEUG.BVE4"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Besondere Vereinbarung"
          :horizontal="true"
          label="Besondere Vereinbarung"
          vias="BVE4"
        />
        <basic-datepicker
          v-model="FAHRZEUG.KMSTANDDATUM2"
          data-test="car-form-registration-date"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          required
          label="Kilometerstand abgelesen am"
          placeholder="TT.MM.JJJJ"
          vias="KMSTANDDATUM2"
        />
        <basic-input
          v-model="FAHRZEUG.PRODUKTKEY"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Produkt Schlüssel"
          :horizontal="true"
          label="Produkt Schlüssel"
          vias="PRODUKTKEY"
        />
        <basic-input
          v-model="FAHRZEUG.PRODUKTBEZ"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Produkt Schlüssel"
          :horizontal="true"
          label="Produkt Schlüssel"
          vias="PRODUKTBEZ"
        />
        <basic-input
          v-model="FAHRZEUG.TARIFKEY"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Tarif Schlüssel"
          :horizontal="true"
          label="Tarif Schlüssel"
          vias="TARIFKEY"
        />
        <basic-input
          v-model="FAHRZEUG.TARIFBEZ"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Tarifbezeichnung"
          :horizontal="true"
          label="Tarifbezeichnung"
          vias="TARIFBEZ"
        />
        <basic-datepicker
          v-model="FAHRZEUG.BEG_RIS"
          data-test="car-form-registration-date"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          required
          label="Vertragsbeginn"
          placeholder="TT.MM.JJJJ"
          vias="BEG_RIS"
        />
        <basic-datepicker
          v-model="FAHRZEUG.ABL_RIS"
          data-test="car-form-registration-date"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          required
          label="Vertragsbeginn"
          placeholder="TT.MM.JJJJ"
          vias="ABL_RIS"
        />
        <basic-input
          v-model="FAHRZEUG.RATENZAHLUNG"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Ratenzahlung"
          :horizontal="true"
          label="Ratenzahlung"
          vias="RATENZAHLUNG"
        />
        <basic-select
          id="GASZUSCHLAGJN"
          v-model="FAHRZEUG.GASZUSCHLAGJN"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Ja/Nein"
          :horizontal="true"
          :margin="false"
          label="Zuschlag Gasantrieb"
          vias="GASZUSCHLAGJN"
          :options="JNOptions"
        />
        <basic-input
          v-model="FAHRZEUG.GASZUSCHLAG"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Aufpreis"
          type="number"
          :horizontal="true"
          label="Aufpreis für Gasantrieb / Elektro / Hybrid"
          vias="GASZUSCHLAG"
        />
        <basic-checkbox
          v-model="FAHRZEUG.P10_1"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="P10_1"
          label="Checkbox 10% 1. VMT"
        />
        <basic-checkbox
          v-model="FAHRZEUG.P10_2"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="P10_2"
          label="Checkbox 10% 2. VMT"
        />
        <basic-checkbox
          v-model="FAHRZEUG.P10_3"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="P10_3"
          label="Checkbox 10% 3. VMT"
        />
        <basic-checkbox
          v-model="FAHRZEUG.P10_3"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="P10_3"
          label="Checkbox 10% 3. VMT"
        />
        <basic-checkbox
          v-model="FAHRZEUG.P5A_1"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="P5A_1"
          label="Checkbox 5%A 1. VMT"
        />
        <basic-checkbox
          v-model="FAHRZEUG.P5A_2"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="P5A_2"
          label="Checkbox 5%A 2. VMT"
        />
        <basic-checkbox
          v-model="FAHRZEUG.P5A_3"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="P5A_3"
          label="Checkbox 5%A 3. VMT"
        />
        <basic-checkbox
          v-model="FAHRZEUG.P5B_1"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="P5B_1"
          label="Checkbox 5%B 1. VMT"
        />
        <basic-checkbox
          v-model="FAHRZEUG.P5B_2"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="P5B_2"
          label="Checkbox 5%B 2. VMT"
        />
        <basic-checkbox
          v-model="FAHRZEUG.P5B_3"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="P5B_3"
          label="Checkbox 5%B 3. VMT"
        />
        <basic-checkbox
          v-model="FAHRZEUG.P25A_1"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="P25A_1"
          label="Checkbox 2,5%A 1. VMT"
        />
        <basic-checkbox
          v-model="FAHRZEUG.P25A_2"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="P25A_2"
          label="Checkbox 2,5%A 2. VMT"
        />
        <basic-checkbox
          v-model="FAHRZEUG.P25A_3"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="P25A_3"
          label="Checkbox 2,5%A 3. VMT"
        />
        <basic-checkbox
          v-model="FAHRZEUG.P25B_1"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="P25B_1"
          label="Checkbox 2,5%B 1. VMT"
        />
        <basic-checkbox
          v-model="FAHRZEUG.P25B_2"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="P25B_2"
          label="Checkbox 2,5%B 2. VMT"
        />
        <basic-checkbox
          v-model="FAHRZEUG.P25B_3"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          vias="P25B_3"
          label="Checkbox 2,5%B 3. VMT"
        />
        <basic-input
          v-model="FAHRZEUG.VW1_PROZ"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Provision in % für den 1. Vermittler"
          :horizontal="true"
          type="number"
          label="Provision in % für den 1. Vermittler"
          vias="VW1_PROZ"
        />
        <basic-input
          v-model="FAHRZEUG.VW2_PROZ"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Provision in % für den 2. Vermittler"
          :horizontal="true"
          type="number"
          label="Provision in % für den 2. Vermittler"
          vias="VW2_PROZ"
        />
        <basic-input
          v-model="FAHRZEUG.VW3_PROZ"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Provision in % für den 3. Vermittler"
          :horizontal="true"
          type="number"
          label="Provision in % für den 3. Vermittler"
          vias="VW3_PROZ"
        />
      </form>
    </template>
  </ca-card>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox'
import BasicCurrencyInput from '@/components/BaseComponents/BasicCurrencyInput'
import CaCard from '@/components/Card'
import SanitizeChassi from '../../../helpers/sanitizeChassi'

export default {
  inject: ['$validator'],
  name: 'FahrzeugForm',
  components: {
    BasicInput,
    BasicSelect,
    BasicDatepicker,
    BasicCheckbox,
    BasicCurrencyInput,
    CaCard
  },
  props: {
    // car: {
    //   type: Object,
    //    required: true
    //  },
    edit: {
      type: Boolean,
      default: false
    },
    isKaufschutz: {
      type: Boolean,
      default: false
    },
    extend: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    FAHRZEUG: {},
    monthOptions: [
      { value: null },
      { label: 'Januar', value: 1 },
      { label: 'Februar', value: 2 },
      { label: 'März', value: 3 },
      { label: 'April', value: 4 },
      { label: 'Mai', value: 5 },
      { label: 'Juni', value: 6 },
      { label: 'Juli', value: 7 },
      { label: 'August', value: 8 },
      { label: 'September', value: 9 },
      { label: 'Oktober', value: 10 },
      { label: 'November', value: 11 },
      { label: 'Dezember', value: 12 }
    ],
    JNOptions: [
      { label: 'Ja', value: 'j' },
      { label: 'Nein', value: 'n' }
    ],
    chassiInputManipulated: false
  }),
  methods: {
    searchCountry (char) {
      return this.$store.dispatch('countries/find', {
        query: {
          $limit: 10, char: char.toUpperCase()
        }
      })
    },
    countryResultFormatter (country) {
      return `${country.char} ${country.name}`
    },
    setCountry (country) {
      this.car.country = {
        ...country
      }
    },
    sanitizeChassi (chassi) {
      const sanitized = SanitizeChassi(chassi)
      this.chassiInputManipulated = sanitized.manipulated
      this.$set(this.car, 'chassi', sanitized.chassi)
    }
  }
}
</script>
