<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Status
      </h4>
    </template>
    <template #body>
      <basic-select
        id="status"
        v-model="order.status"
        label="Status"
        :horizontal="true"
        :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
        :options="statusOptions"
      />
    </template>
  </ca-card>
</template>
<script>
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import CaCard from '@/components/Card'

export default {
  name: 'StatusForm',
  inject: ['$validator'],
  components: {
    BasicSelect,
    CaCard
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    statusOptions: [
      { label: 'Anfrage', value: 0 },
      { label: 'Vertrag', value: 1 },
      { label: 'Abgelaufen', value: 2 },
      { label: 'Storno', value: 3 },
      { label: 'Abgelehnt', value: 4 }
    ]
  })
}
</script>
