<template>
  <div v-if="loading">
    Lädt...
  </div>
  <div v-else>
    <ca-header
      heading="Bearbeiten"
      :column-layout="columnLayout"
      show-column-layout
      @columnLayout="columnLayout = $event"
    >
      <template #alert>
        <ca-alert
          v-if="!!saveErrorMessage"
          variant="danger"
          :message="saveErrorMessage"
          @close="saveErrorMessage = null"
        />
        <ca-alert
          v-if="showFormInvalidHint"
          variant="info"
          message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
          @close="showFormInvalidHint = null"
        />
      </template>
      <template #buttonSpace>
        <submit-buttons
          edit
          :pending="savePending"
          @save="save"
        />
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div
        class="btn-group mb-3"
      >
        <button
          type="button"
          class="btn"
          :class="{ 'btn-primary' : (tab === 0), 'btn-secondary' : (tab !== 0) }"
          @click="tab = 0"
        >
          Vertrag
        </button>
        <button
          type="button"
          class="btn"
          :class="{ 'btn-primary' : (tab === 1), 'btn-secondary' : (tab !== 1) }"
          @click="tab = 1"
        >
          Halter
        </button>
        <button
          type="button"
          class="btn"
          :class="{ 'btn-primary' : (tab === 2), 'btn-secondary' : (tab !== 2) }"
          @click="tab = 2"
        >
          Fahrzeug
        </button>
        <button
          type="button"
          class="btn"
          :class="{ 'btn-primary' : (tab === 3), 'btn-secondary' : (tab !== 3) }"
          @click="tab = 3"
        >
          OLD
        </button>
      </div>
      <div
        v-if="!loading"
        class="row"
      >
        <div
          class="col-12"
          :class="{'col-xl-8 col-xxl-6': !columnLayout}"
        >
          <div class="row">
            <div
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <status-form
                v-if="tab==0 || tab==3"
                class="mb-3"
                :order="order"
              />
              <VertragForm
                v-if="tab==0"
                :order="order"
              />
              <HalterForm
                v-if="tab==1"
                :order="order"
              />
              <FahrzeugForm
                v-if="tab==2"
                :is-kaufschutz="order.isKaufschutz"
                :car="order"
              />
              <gvc-form
                v-if="tab==3 && order.gvcs && order.gvcs.length > 0"
                class="mb-3"
                edit
                :order="order"
              />
              <owner-form
                v-if="tab==3"
                class="mb-3"
                edit
                :order="order"
              />
              <miscellaneous-form
                v-if="tab==0 || tab==3"
                class="mb-3"
                edit
                :order="order"
              />
            </div>
            <div
              v-if="tab==3"
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <car-form
                class="mb-3"
                edit
                :is-kaufschutz="order.isKaufschutz"
                :car="order.car"
              />
              <template v-if="order.isKaufschutz">
                <kaufschutz-product-form
                  ref="productForm"
                  class="mb-3"
                  edit
                  :products="products"
                  :order="order"
                  :no-rate-found="noRateFound"
                />
              </template>
              <template v-else>
                <product-form
                  ref="productForm"
                  class="mb-3"
                  edit
                  :order="order"
                  :rates="rates"
                  :end-date-selectable="order.productType === 'SORGLOS_FLAT'"
                  :products="products"
                  :rates-pending="ratesPending"
                  @productSelected="findRates"
                />
              </template>
            </div>
          </div>

          <div
            v-if="!loading"
            class="form-row justify-content-end"
          >
            <submit-buttons
              class="col-auto"
              edit
              :pending="savePending"
              @save="save"
            />
          </div>
        </div>
        <dev-info :data="{order}" />
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header.vue'
import DevInfo from '@/components/DevInfo.vue'
import StatusForm from '@/components/Forms/Order/StatusForm.vue'
import OwnerForm from '@/components/Forms/Order/OwnerForm.vue'
import CarForm from '@/components/Forms/Order/CarForm.vue'
import GvcForm from '@/components/Forms/Order/GvcForm.vue'
import MiscellaneousForm from '@/components/Forms/Order/MiscellaneousForm.vue'
import ProductForm from '@/components/Forms/Order/ProductForm.vue'
import KaufschutzProductForm from '@/components/Forms/Order/KaufschutzProductForm.vue'
import SubmitButtons from '@/components/Forms/Order/SubmitButtons.vue'
import transformRateList from '../../helpers/transformRateList.js'
import FahrzeugForm from '@/components/Forms/Order/FahrzeugForm'
import HalterForm from '@/components/Forms/Order/HalterForm'
import VertragForm from '@/components/Forms/Order/VertragForm'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  name: 'OrderEdit',
  components: {
    DevInfo,
    OwnerForm,
    CarForm,
    GvcForm,
    MiscellaneousForm,
    ProductForm,
    KaufschutzProductForm,
    SubmitButtons,
    StatusForm,
    CaHeader,
    FahrzeugForm,
    HalterForm,
    VertragForm
  },
  data () {
    return {
      loading: false,
      error: null,
      columnLayout: false,
      order: null,
      rates: [],
      products: [],
      noRateFound: false,
      ratesPending: false,
      savePending: false,
      saveError: null,
      saveErrorMessage: null,
      showFormInvalidHint: null,
      tab: 0
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    },
    'order.product.productId': {
      handler: 'findKaufschutzRates'
    },
    'order.car.price': {
      handler: 'findKaufschutzRates'
    },
    'order.car.km': {
      handler: 'findKaufschutzRates'
    },
    'order.car.monthsFromRegistrationDate': {
      handler: 'findKaufschutzRates'
    },
    'order.product.duration': {
      handler: 'findKaufschutzRates'
    }
  },
  methods: {
    async save () {
      try {
        this.savePending = true
        this.saveError = null
        this.saveErrorMessage = null
        this.saveSuccess = false
        this.showFormInvalidHint = false

        if (this.order.gvcs && this.order.gvcs.length > 0) {
          const setAllGVCsInvalid = this.order.gvcs.some(gvc => gvc.status > 3)
          for (const gvc of this.order.gvcs) {
            if ((this.order.status > 1 || setAllGVCsInvalid) && (gvc.status === 0 || gvc.status === 1)) { gvc.status = 4 }
            await this.$store.dispatch('gvcs/patch', [gvc._id, gvc])
          }
        }
        const priceModifier = this.$refs.productForm.priceModifier
        await this.$store.dispatch('orders/patch', [this.order._id, this.order, { query: { $client: { priceModifier } } }])
        await this.$router.push({ name: 'OrderDetail', params: { orderId: this.order._id } })
      } catch (error) {
        console.log(error)
        let msg = ''
        switch (error.message) {
        case 'Authentication token missing.': msg = 'Sie sind nicht mehr eingeloggt. Loggen Sie sich neu ein'; break
        case 'Das Erstellen von Policen/Anfragen ist für diesen Account gesperrt': msg = error.message; break
        default: msg = 'Der Server konnte die Anfrage nicht bearbeiten'
        }
        this.saveErrorMessage = msg
        this.saveError = error
      } finally {
        this.savePending = false
      }
    },
    async fetchData () {
      try {
        this.loading = true
        this.order = this.$store.getters['orders/get'](this.$route.params.orderId) ||
          await this.$store.dispatch('orders/get', this.$route.params.orderId)
        await this.findProducts()
        if (this.order.rateId) {
          this.rates.push(await this.$store.dispatch('rates/get', this.order.rateId))
        } else {
          if (this.order.product && this.order.product.rate && this.order.product.rate.product) {
            const product = this.products.find(product => product.hn.toString() === this.order.product.rate.product.hn.toString())
            this.rates = await this.$store.dispatch('rates/find', {
              query: {
                $limit: 1,
                un: this.order.product.rate.un,
                productId: product._id,
                $client: {
                  paginate: false
                }
              }
            })
          }
        }
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async findKaufschutzRates () {
      if (this.order.isKaufschutz && this.order.product && this.order.product.productId) {
        const rateRequest = await this.$store.dispatch('kaufschutz-rates/find', {
          query: {
            $limit: 1,
            productId: this.order.product.productId,
            km: this.order.car.km,
            monthFromRegistrationDate: this.order.car.monthFromRegistrationDate,
            carPriceFrom: {
              $lte: this.order.car.price
            },
            carPriceTo: {
              $gte: this.order.car.price
            },
            laufzeit: this.order.product.duration
          }
        })
        const rate = rateRequest.data[0]
        if (rate) {
          this.$set(this.order.product, 'rate', rate)
          this.$set(this.order.product, 'rateId', rate._id)
          this.noRateFound = false
        } else {
          this.$delete(this.order.product, 'rate')
          this.$delete(this.order.product, 'rateId')
          this.noRateFound = true
        }
      }
    },
    async findProducts () {
      if (this.order.isKaufschutz) {
        this.products = await this.$store.dispatch('products/find', {
          query: {
            $client: { paginate: false },
            $sort: {
              hn: 1
            },
            isKaufschutz: true
          }
        })
      } else {
        this.products = await this.$store.dispatch('products/find', {
          query: {
            $client: {
              paginate: false
            },
            $sort: {
              hn: 1
            }
          }
        })
      }
    },
    async findRates (productId) {
      if (!this.order.isKaufschutz) {
        this.ratesPending = true
        const rateRequest = await this.$store.dispatch('rates/find', {
          query: {
            productId,
            $client: {
              paginate: false
            }
          }
        })
        this.rates = transformRateList({ data: rateRequest }, false)
        this.ratesPending = false
      }
    },
    async closeModal () {
      await this.$router.push({ name: 'OrderDetail', params: { orderId: this.savedOrder._id } })
      this.showOrderModal = false
    }
  }
}
</script>

<style scoped lang="scss">

.order-info{
  position: sticky;
  top: 70px;
}

</style>
